import {TranslocoEvents, TranslocoService} from '@jsverse/transloco';
import _ from 'lodash';
import {filter} from 'rxjs';

export function TranslocoChangedFilter(event: TranslocoEvents, service: TranslocoService) {
  if (event.type === 'langChanged') {
    return !_.isEmpty(service.getTranslation(event.payload.langName));
  } else if (event.type === 'translationLoadSuccess') {
    return true;
  } else {
    return false;
  }
}

export function TranslocoChangedEvent(service: TranslocoService) {
  return service.events$.pipe(filter((event) => TranslocoChangedFilter(event, service)));
}
